.btn-primary {
    background-color: #00acc1;
    border-color: #00acc1;
}

.btn-primary:hover {
    background-color: #008799;
    border-color: #008799;
    box-shadow: 0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #00acc1;
    border-color: #00acc1;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #00acc1 !important;
}