@media screen {
    .page-header {
        display: none;
    }
    .page-footer {
        display: none;
    }
    .page-number {
        display: none;
    }
    .page-header-left {
        display: none;
    }
    .page-footer-left {
        display: none;
    }
}
.block {
    position: relative;
    width: 170px;
    height: 120px;
    border: 1px solid #0099ff;
    margin-top: 50px;
    margin-bottom: 100px;
    color: black;
    text-align: center;
}
.dailystatiticsblock {
    position: relative;
    height: 700px;
    border: 1px solid #0099ff;
    margin-top: 50px;
    margin-bottom: 100px;
    color: black;
    text-align: center;
}
.square {
    position: relative;
    width: 10%;
    border: 1px solid #0099ff;
    margin-top: 20px;
    color: black;
    text-align: center;
}

@media print {
    body {
        counter-reset: counter;
    }
    .page-number::before {
        counter-increment: counter 1;
        content: "Page " counter(counter) ". ";
    }
    .page-number {
        position: relative;
        color: black;
        top: 1510px;
        margin-left: 500px;
    }
    .colorClass {
        color: black;
    }
    .page-header {
        position: relative;
        top: 0;
        right: 0;
        /* margin-bottom: 50px; */
        margin-left: 700px;
        margin-right: 10px;
    }

    .page-footer {
        bottom: 0;
        right: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
    .page-header-left {
        position: relative;
        top: 0;
        left: 0;
        margin-left: 10px;
        margin-right: 10px;
    }

    .page-footer-left {
        position: fixed;
        bottom: 0;
        left: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar {
        display: none;
    }
}
